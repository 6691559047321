<template>
  <div>
    <h1>交通費マスタ</h1>
    <v-card outlined class="mb-5">
      <!-- Expansion -->
      <v-expansion-panels flat>
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>

          <!-- Body -->
          <v-expansion-panel-content>
            <v-autocomplete
              v-model="selectCarType"
              :items="filterCarTypes"
              label="車種"
              clearable
              dense
              item-text="name"
              item-value="code"
            >
            </v-autocomplete>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Actions -->
      <v-card-actions class="ma-2">
        <v-spacer />
        <Btn color="info" icon="mdi-magnify" @click="select">検索</Btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-5">
      <v-card-actions class="ma-2">
        <v-spacer />
        <Btn
          icon="mdi-lead-pencil"
          color="success"
          :disabled="$isEmpty(items.length)"
          @click="update"
        >
          更新
        </Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <!-- Table -->
    <v-card class="mt-5">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <Btn color="info" icon="plus" text @click="addRow()">レコード追加</Btn>
      <table class="my-5 table">
        <thead>
          <tr>
            <th v-for="(header, i) in headers" :key="i" class="table-th">
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>
              {{ item.carTypeDisplay }}
            </td>
            <td>
              <v-text-field
                v-model.number="item.distanceFrom"
                type="number"
                step="0.1"
                suffix="km"
                class="right-input"
                :rules="[Rules.Required]"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model.number="item.distanceTo"
                type="number"
                step="0.1"
                suffix="km"
                class="right-input"
                :rules="[Rules.Required]"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model.number="item.maxAmount"
                type="number"
                step="1"
                suffix="円"
                class="right-input"
                :rules="[Rules.Required]"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model.number="item.amount"
                type="number"
                step="1"
                suffix="円"
                class="right-input"
                :rules="[Rules.Required]"
              ></v-text-field>
            </td>
            <td>
              <v-icon
                small
                class="ml-1"
                color="error"
                @click="deleteAction(item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import { CODE_GROUP as CodeGroup } from "@/defines";

export default {
  name: "TransportationExpenses",
  mixins: [Common, Forms, ShowDialogs, Api],
  props: { args: Object },
  data() {
    return {
      params: null,
      selected: [],
      search: "",
      open: true,
      title: "テーブル名",
      itemKey: "name",
      headers: [],
      items: [],
      selectCarType: {},
      filterCarTypes: [],
      panel: null,
      carTypes: []
    };
  },
  computed: {
    isSelected() {
      return !!this.selected && this.selected.length > 0;
    }
  },
  methods: {
    async select() {
      console.log("select");

      const param = {
        carType: this.selectCarType
      };
      const encode = encodeURI(JSON.stringify(param));
      const data = await this.$get(
        this.Paths.transportationExpenses,
        "query=" + encode
      );
      await this.setdata(data);
    },
    async getData() {
      console.log("getData");
      const data = await this.$get(this.Paths.transportationExpenses);
      await this.setdata(data);
    },
    async setdata(data) {
      console.log("setdata");
      this.headers = data.headers;
      this.items = data.items;
      this.title = data.name;
      this.itemKey = data.key;
    },
    async getSearchCondition() {
      console.log("getSearchCondition");
      const codeMasters = await this.$get(this.Paths.codeMasterPulldown);
      this.filterCarTypes = codeMasters.filter(
        e => e.refcode === CodeGroup.FCAR
      );
      if (this.filterCarTypes.length > 0) {
        this.selectCarType = this.filterCarTypes[0].code;
      }
      this.carTypes = this.filterCarTypes;
    },
    addRow() {
      console.log("addRow");
      const selectedItem = this.carTypes.filter(
        e => e.code === this.selectCarType
      );
      this.items.push({
        carType: selectedItem[0].code,
        carTypeDisplay: selectedItem[0].name,
        distanceFrom: 0,
        distanceTo: 0,
        maxAmount: 0,
        amount: 0
      });
    },
    async update() {
      console.log("update");
      let validate = true;
      for (let i = 0; i < this.items.length; i++) {
        const row = this.items[i];
        const r = this.items.filter(
          f =>
            f.cartType === row.cartType &&
            f.distanceFrom === row.distanceFrom &&
            f.distanceTo === row.distanceTo
        );
        if (r.length > 1) {
          this.$warning("重複する設定が存在します。");
          validate = false;
          break;
        }
      }
      if (!validate) {
        return false;
      }

      this.$loading();
      try {
        const params = [];
        this.items.forEach(e => {
          params.push({
            carType: e.carType.code ? e.carType.code : e.carType,
            distanceFrom: e.distanceFrom,
            distanceTo: e.distanceTo,
            maxAmount: e.maxAmount,
            amount: e.amount
          });
        });

        await this.$post(this.Paths.transportationExpenses, params);
        await this.$info("更新しました。");
        await this.select();
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async deleteAction(item) {
      console.log("deleteAction", item);
      const confirm = await this.$deleteConfirm(
        "削除します。\nよろしいですか？\n(データの反映には更新が必要です)"
      );
      console.log(confirm);
      if (!confirm) return;

      this.items = this.items.filter(e => e !== item);
    }
  },
  async created() {
    console.log("==created");
    this.$loading();
    try {
      await this.getSearchCondition();
    } catch (e) {
      console.log(e);
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
<style>
.v-data-table td {
  background: #f0f8ff;
}
.v-data-table tr:nth-child(odd) td {
  background: #fff;
}
.v-data-table tr:hover td {
  background-color: #eee;
}
.table {
  width: 100%;
}
.table-th {
  width: 20%;
  font-weight: normal;
}
.right-input input {
  text-align: right;
}
</style>
